import io from 'socket.io-client';
import * as ACTIONS from '../constants/actions';

export default class Socket {
    constructor({dispatch}){
        this.dispatch = dispatch;
        
        this.socket = io(`${process.env.REACT_APP_BASE_URL}`, {path: '/socket', reconnect: false,});
        this.socket.on('message', (msg) => {
            console.log(msg)
            this.dispatch({type: ACTIONS.SET_SENSOR_DATA, payload: msg.det});
            this.dispatch({type: ACTIONS.SET_SENSOR_LOCATION, payload: msg.loc});
        });

        this.socket.on('connect', () => {
            console.log("We are connected")
            this.joinRooms({rooms: ["Test1"]})
        })
    }

    clearRooms = () => {
        this.socket.emit("clear-rooms");
    }

    joinRooms = (data) => {
        console.log("Joining: ", data);
        this.socket.emit("join-rooms", data);
    }
}