import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux'
import * as moment from 'moment';
import {Image} from 'react-bootstrap'
import './index.scss';
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../../helpers/api';
import { useDispatch } from 'react-redux';

export default function Navbar(): JSX.Element{
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [api] = useState(() => new SpartanApi({dispatch}))
    //@ts-ignore
    const user     = useSelector(s => s.user.data);
    console.log(user)
    return (
        <div className="spartan-navbar">
            <div className="logo-container">
            <Image 
                src="https://spartanradarco.wpenginepowered.com/wp-content/uploads/2021/06/SpartanRadar_Logo_Light.svg"
                fluid={true}
            />
            </div>
            {user ? (<div className="menu-option-container">
                
                <div onClick={() => navigate('/detection-history')} className="menu-option">
                    <strong>Detection History</strong>
                </div>

                <div onClick={() => navigate('/live-monitoring')} className="menu-option">
                    <strong>Live Monitoring</strong>
                </div>
            </div>): null}
        </div>
    )
}