import axios from 'axios';
import moment from 'moment';
import * as ACTIONS from '../constants/actions';
import * as KEYS    from '../constants/keys';
import { result } from '../components/DetectionHistory/result';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export default class SpartanApi{
    constructor({dispatch}){
        this.dispatch = dispatch;
        this.loadUser();
    }

    setToken = (token) => {
        axios.defaults.headers['authorization'] = token;
        window.localStorage.setItem(KEYS.USER_TOKEN_KEY, token);
    }

    getToken = () => {
        const token = window.localStorage.getItem(KEYS.USER_TOKEN_KEY);
        if (token){
            axios.defaults.headers['authorization'] = token;
        }
        return token;
    }

    cacheUser = (user) => {
        console.log("USER: ", user)
        window.localStorage.setItem(KEYS.USER_DATA_KEY, JSON.stringify(user));
    }

    loadUser = () => {
        console.log("WORKING")
        try {
            let user = window.localStorage.getItem(KEYS.USER_DATA_KEY);
            console.log(user)
            if (!user) return;
            user  = JSON.parse(user);
            console.log(user)
            this.dispatch({type: ACTIONS.SET_USER_DATA, payload: user});
        } catch (err) {
            console.log(err);
        }
    }

    attemptSignIn = async(request) => {
        try {
            console.log("Fixed")
            const {data} = await axios.post('/user/sign-in', request);
            const {user, token} = data;
            this.dispatch({type: ACTIONS.SET_USER_DATA, payload: user});
            this.setToken(token);
            this.cacheUser(user);
            return user;
        } catch(err) {
            console.log("ERROR SIGNING IN: ", err);
            return null;
        }
    }

    attemptQuery = async (request) => {
        try {
            console.log("QUERYING")
            const {data} = await axios.get('/radar-data/query-internal-radar-data', {params: request});
            let results = data.results.map((r) => {
                let fresh = {...r};
                if (fresh.location){
                    fresh.location = JSON.parse(fresh.location)
                }

                if (fresh.rawData){
                    fresh.rawData = JSON.parse(fresh.rawData)
                }

                if (fresh.detections){
                    fresh.detections = JSON.parse(fresh.detections).detections;
                } 

                return fresh
            }).sort((a, b) => {
                return moment(a.timeCaptured).unix() - moment(b.timeCaptured).unix()
            })
           
            this.dispatch({type: ACTIONS.SET_HISTORY, payload: results})
        } catch(err) {
            console.log("ERROR RETRIEVING THE DATA");
            return null;
        }
    }

}