import { Container, Row, Col, Card, Form, Button, Table } from "react-bootstrap";
import SpartanApi from "../../helpers/api";
import {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import moment from 'moment'
import { useSelector } from "react-redux";
import { result } from "./result";
import BingMapsReact from 'bingmaps-react'
import  Worldview, { Cubes, GLTFScene} from 'regl-worldview';
//@ts-ignore
import dodgeTruck from '../../assets/models/dodge_ram_3500.glb'

export default function DetectionHistory(): JSX.Element{
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const [activeResult, setActiveResult] = useState(() => null);
    const [activeIndex, setActiveIndex] = useState(() => undefined);
    const [mapReady, setMapReady] = useState(() => false);
    const [query, setQuery] = useState(() => {
        return {
            sensorId: 'TestCan',
            timeStart: undefined,
            timeEnd: undefined
        }
    })
    //@ts-ignore
    const results : result[] = useSelector(s => s.history.results) as result[];
    //@ts-ignore
    const user = useSelector(s => s.user.data);

    const [camState, setCamState] = useState(() => {
        return {
            "distance": 55.61357139462458,
            "perspective": true,
            "phi": 0.7853981633974483,
            "target": [
                0,
                0,
                0
            ],
            "targetOffset": [
                -5.123340451318355,
                -0.5082679019165036,
                0
            ],
            "targetOrientation": [
                0,
                0,
                0,
                1
            ],
            "thetaOffset": 0,
            "fovy": 0.7853981633974483,
            "near": 0.01,
            "far": 5000
        }
    });
    const [zones, setZones] = useState( () => [
        //Segment One
        //Zone One
        {
          pose: {
            orientation: { x: 0, y: 0, z: 0, w: 1 },
            position: { x: -10, y: 5, z: 0 },
          },
          scale: { x: 2, y: 5, z: 2 },
          color: { r: 1, g: 1, b: 1, a: 0.5 }
        },
        //S1Z2
        {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -12, y: 5, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
        //S1Z3
        {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -14, y: 5, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
        },
        //S1Z4
        {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -16, y: 5, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
        //S1Z5
        {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -18, y: 5, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
        //S2Z1
        {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -10, y: 0.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
        //S2Z2
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -12, y: 0.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
          //S2Z3
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -14, y: 0.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
          //S2Z4
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -16, y: 0.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
          //S2Z5
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -18, y: 0.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
          //S3Z1
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -10, y: -5.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -12, y: -5.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -14, y: -5.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -16, y: -5.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -18, y: -5.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
    ]);

    const [map, setMap] = useState(() => [0,0,0])

    const defaultZoneColor = { r: 1, g: 1, b: 1, a: 0.5 }
    const redZoneColor = {r: 255, g:0, b: 0, a:0.75}
    const yellowZoneColor = { r: 255, g: 165, b: 0, a: 0.75 }

    useEffect(() => {
        //@ts-ignore
        if (!mapReady) return;
        const z = zones.map(zone => { return{...zone, color: defaultZoneColor}});
        //@ts-ignore
        const s = map;

        if (s[0]){
         
            //Light Up the Appropriate Zone
            if (s[0] == 1){
                z[0].color = redZoneColor;
                z[1].color = redZoneColor;
                z[2].color = redZoneColor;
                z[3].color = redZoneColor;
                z[4].color = redZoneColor;
            } else if (s[0] == 2){
                z[1].color = redZoneColor;
                z[2].color = redZoneColor;
                z[3].color = redZoneColor;
                z[4].color = redZoneColor;
            } else if (s[0] == 3){
                z[2].color = redZoneColor;
                z[3].color = redZoneColor;
                z[4].color = redZoneColor;
            } else if (s[0] == 4) {
             
                z[3].color = redZoneColor;
                z[4].color = redZoneColor;
            } else if (s[0] == 5) {
                z[4].color = redZoneColor;
            }
        }

        if (s[1]){
         
            //Light Up the Appropriate Zone
            if (s[1] == 1){
                z[5].color = redZoneColor;
                z[6].color = redZoneColor;
                z[7].color = redZoneColor;
                z[8].color = redZoneColor;
                z[9].color = redZoneColor;
            } else if (s[1] == 2){
                z[6].color = redZoneColor;
                z[7].color = redZoneColor;
                z[8].color = redZoneColor;
                z[9].color = redZoneColor;
            } else if (s[1] == 3){
                z[7].color = redZoneColor;
                z[8].color = redZoneColor;
                z[9].color = redZoneColor;
            } else if (s[1] == 4) {
                z[8].color = redZoneColor;
                z[9].color = redZoneColor;
            } else if (s[1] == 5) {
                z[9].color = redZoneColor;
            }
        }

        if (s[2]){
           
            //Light Up the Appropriate Zone
            if (s[2] == 1){
                z[10].color = redZoneColor;
                z[11].color = redZoneColor;
                z[12].color = redZoneColor;
                z[13].color = redZoneColor;
                z[14].color = redZoneColor;
            } else if (s[2] == 2){
                z[11].color = redZoneColor;
                z[12].color = redZoneColor;
                z[13].color = redZoneColor;
                z[14].color = redZoneColor;
            } else if (s[2] == 3){
                z[12].color = redZoneColor;
                z[13].color = redZoneColor;
                z[14].color = redZoneColor;
            } else if (s[2] == 4) {
                z[13].color = redZoneColor;
                z[14].color = redZoneColor;
            } else if (s[2] == 5) {
                z[14].color = redZoneColor;
            }
        }
        
        setZones(z)
       
    }, [map])

    const submitQuery = async (evt) => {
        evt.preventDefault();

        const requestObj = {
            sensorId: 'TestCan', //WILL NEED TO ACCOUNT FOR MULTIPLE SENSORS
            timeStart:moment(query.timeStart).format(),
            timeEnd: moment(query.timeEnd).format()
        }

        console.log(requestObj)

        await api.attemptQuery(requestObj);
    }

    if (!user) return null;
    return (
        <Container>
            <Row>
                <Col>
                <Card bg="dark" text='white' >
                <Card.Header className="text-center">
                    <h4>Query Sensor History</h4>
                </Card.Header>
                <Card.Body className="text-center">
                    <Form onSubmit={submitQuery}>
                        <Form.Group>
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control type='datetime-local' 
                                          value={query.timeStart}
                                          onChange={evt => setQuery({...query, timeStart: evt.target.value})}
                                          required />
                        </Form.Group>
                        <Form.Group className="mt-5">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control type='datetime-local' 
                                          value={query.timeEnd}
                                          onChange={evt => setQuery({...query, timeEnd: evt.target.value})}
                                          required />
                        </Form.Group>
                        <Button type="submit" variant="primary" className="mt-5"> Query </Button>
                    </Form>
                </Card.Body>
            </Card>


            <Card className="mt-5" bg="dark" text="white">
                        <Card.Header className="text-center">
                            <h4>Results</h4>
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover variant="dark">
                                <tr>
                                    <th>Date/Time Captured</th>
                                    <th>Detection</th>
                                    <th>Latitude</th>
                                    <th>Longitude</th>
                                </tr>

                                {results.map((r, i) => {
                                    return (
                                        <tr className={activeIndex == i ? 'bg-success': ''}
                                        
                                            onClick={() => {
                                             setActiveResult(r)
                                             setActiveIndex(i)
                                             setMap(r.detections)
                                             }} key={i}>
                                            <td>{moment(r.timeCaptured).format()}</td>
                                            <td>{JSON.stringify(r.detections)}</td>
                                            <td>{r.location.latitude.toFixed(6)}</td>
                                            <td>{r.location.longitude.toFixed(6)}</td>
                                        </tr>
                                    )
                                })}
                            </Table>
                        </Card.Body>
                    </Card>
            
            
            </Col>


                <Col>
                    
                {activeResult ? (<Card className="mt-5" bg="dark" text="white">
                        <Card.Header className="text-center">
                            <h4>DETECTION VIEW</h4>
                        </Card.Header>
                        <Card.Body>
                        <div style={{height: 500, width: 500, margin: '0 auto'}}>
                    <Worldview blend={{enable: true}} 
                               cameraState={camState}
                                onCameraStateChange={(newCamState) => {
                        console.log(newCamState)
                                setCamState(newCamState)
                    }}>
                    <Cubes blend={{enable: true}}>{zones}</Cubes>
                    
                    <GLTFScene model={dodgeTruck}>
                        {{
          pose: {
            position: { x: 0, y: 0, z: 0 },
            orientation: { x: 0, y: 0, z: 0, w: 1 },
          },
          scale: { x: 3, y: 3, z: 3 },
          blend: {enable: true}
        }}
                        </GLTFScene>

                        
                    </Worldview>
                </div>
                        </Card.Body>
                </Card> ): null }

                {activeResult ?( <Card className="mt-5" bg="dark" text="white">
                        <Card.Header className="text-center">
                            <h4>MAP VIEW</h4>
                        </Card.Header>
                        <Card.Body className="text-center">

                        {<BingMapsReact 
                    pushPins={mapReady ? [
                        {               //@ts-ignore
                            center:  { latitude: activeResult.location && activeResult.location.latitude ? activeResult.location.latitude : 33.80941166666666, 
                                        //@ts-ignore
                                        longitude: activeResult.location && activeResult.location.longitude ? activeResult.location.longitude :  -118.05834166666666 },
                            options: {
                                title: "SENSOR LOCATION"
                            }
                        }
                    ]: null}
                    bingMapsKey={process.env.REACT_APP_BING_KEY}
                    height="500px"
                    mapOptions={{
                        navigationBarMode: "square"
                    }}
                    onMapReady={() => {
                        setMapReady(true)
                    }}
                    width="500px"
                    viewOptions={{
                         //@ts-ignore
                         center:  { latitude: activeResult.location && activeResult.location.latitude ? activeResult.location.latitude : 33.80941166666666, 
                            //@ts-ignore
                            longitude: activeResult.location && activeResult.location.longitude ? activeResult.location.longitude :  -118.05834166666666 },
                        mapTypeId: "road",
                        zoom: 18,
                    }}
                />}
                            
                        </Card.Body>
                </Card>) : null }
                   
                </Col>
            </Row>
        </Container>
    )
}