import {useState, useEffect, FormEvent} from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Card, Form } from 'react-bootstrap';
import SpartanApi from '../../helpers/api';
import { useDispatch, useSelector } from 'react-redux';

export default function Login() : JSX.Element{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}))
    const [loginUser, setLoginUser] = useState(() => {
        return {
            email: '',
            user_password: ''
        }
    })
    const appState = useSelector(s => s);
    console.log(appState)
    const attemptLogin = async (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        const user =  await api.attemptSignIn(loginUser);
        if (user) return navigate('/live-monitoring')
    }

    useEffect(() => {

    }, [])
    return (
        <Container>
            <Card bg="dark" text='white'>
                <Card.Header className="text-center">
                    <h4>Please Login</h4>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={attemptLogin}>
                        <Form.Group className="mb-5 col-6 offset-3">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email"
                                          value={loginUser.email}
                                          onChange={evt => setLoginUser({...loginUser, email: evt.target.value})}
                                          required={true} />
                        </Form.Group>

                        <Form.Group className="mb-5 col-6 offset-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password"
                                          value={loginUser.user_password}
                                          onChange={evt => setLoginUser({...loginUser, user_password: evt.target.value})}
                                          required={true} />
                        </Form.Group>
                        <Row>
                        <Button type="submit" variant='primary'>Login</Button>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    )
}