import { combineReducers } from "redux";
import * as ACTIONS from '../constants/actions';

export default combineReducers({
    detections: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_SENSOR_DATA: {
                return action.payload;
            }
            default: return state;
        }
    },
    location: (state = null, action) => {
        switch(action.type){
            case ACTIONS.SET_SENSOR_LOCATION: {
                return action.payload;
            }
            default: return state;
        }
    }
})