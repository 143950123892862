import {useSelector, useDispatch} from 'react-redux'
import {Row, Col} from 'react-bootstrap';
import BingMapsReact from 'bingmaps-react'
import {useState, useEffect} from 'react';
import  Worldview, { Cubes, GLTFScene} from 'regl-worldview';
//@ts-ignore
import dodgeTruck from '../../assets/models/dodge_ram_3500.glb'//'src\assets\models\dodge_ram_3500.glb'
import Socket from '../../helpers/socket';
export default function LiveMonitoring(): JSX.Element {
    const dispatch = useDispatch();
    const AppState  = useSelector(s => s)
    const [socket] = useState(() => new Socket({dispatch}))
    const [mapReady, setMapReady] = useState(() => false);
    const [camState, setCamState] = useState(() => {
        return {
            "distance": 55.61357139462458,
            "perspective": true,
            "phi": 0.7853981633974483,
            "target": [
                0,
                0,
                0
            ],
            "targetOffset": [
                -5.123340451318355,
                -0.5082679019165036,
                0
            ],
            "targetOrientation": [
                0,
                0,
                0,
                1
            ],
            "thetaOffset": 0,
            "fovy": 0.7853981633974483,
            "near": 0.01,
            "far": 5000
        }
    });
    const [zones, setZones] = useState( () => [
        //Segment One
        //Zone One
        {
          pose: {
            orientation: { x: 0, y: 0, z: 0, w: 1 },
            position: { x: -10, y: 5, z: 0 },
          },
          scale: { x: 2, y: 5, z: 2 },
          color: { r: 1, g: 1, b: 1, a: 0.5 }
        },
        //S1Z2
        {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -12, y: 5, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
        //S1Z3
        {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -14, y: 5, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
        },
        //S1Z4
        {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -16, y: 5, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
        //S1Z5
        {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -18, y: 5, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
        //S2Z1
        {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -10, y: 0.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
        //S2Z2
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -12, y: 0.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
          //S2Z3
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -14, y: 0.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
          //S2Z4
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -16, y: 0.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
          //S2Z5
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -18, y: 0.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
          //S3Z1
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -10, y: -5.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -12, y: -5.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -14, y: -5.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -16, y: -5.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
          {
            pose: {
              orientation: { x: 0, y: 0, z: 0, w: 1 },
              position: { x: -18, y: -5.1, z: 0 },
            },
            scale: { x: 2, y: 5, z: 2 },
            color: { r: 1, g: 1, b: 1, a: 0.5 },
          },
    ]);

    const defaultZoneColor = { r: 1, g: 1, b: 1, a: 0.5 }
    const redZoneColor = {r: 255, g:0, b: 0, a:0.75}
    const yellowZoneColor = { r: 255, g: 165, b: 0, a: 0.75 }
    
    useEffect(() => {
        //@ts-ignore
        if (!mapReady || !AppState.sensor.detections) return;
        const z = zones.map(zone => { return{...zone, color: defaultZoneColor}});
        //@ts-ignore
        const s = AppState.sensor.detections;

        if (s[0]){
         
            //Light Up the Appropriate Zone
            if (s[0] == 1){
                z[0].color = redZoneColor;
                z[1].color = redZoneColor;
                z[2].color = redZoneColor;
                z[3].color = redZoneColor;
                z[4].color = redZoneColor;
            } else if (s[0] == 2){
                z[1].color = redZoneColor;
                z[2].color = redZoneColor;
                z[3].color = redZoneColor;
                z[4].color = redZoneColor;
            } else if (s[0] == 3){
                z[2].color = redZoneColor;
                z[3].color = redZoneColor;
                z[4].color = redZoneColor;
            } else if (s[0] == 4) {
             
                z[3].color = redZoneColor;
                z[4].color = redZoneColor;
            } else if (s[0] == 5) {
                z[4].color = redZoneColor;
            }
        }

        if (s[1]){
         
            //Light Up the Appropriate Zone
            if (s[1] == 1){
                z[5].color = redZoneColor;
                z[6].color = redZoneColor;
                z[7].color = redZoneColor;
                z[8].color = redZoneColor;
                z[9].color = redZoneColor;
            } else if (s[1] == 2){
                z[6].color = redZoneColor;
                z[7].color = redZoneColor;
                z[8].color = redZoneColor;
                z[9].color = redZoneColor;
            } else if (s[1] == 3){
                z[7].color = redZoneColor;
                z[8].color = redZoneColor;
                z[9].color = redZoneColor;
            } else if (s[1] == 4) {
                z[8].color = redZoneColor;
                z[9].color = redZoneColor;
            } else if (s[1] == 5) {
                z[9].color = redZoneColor;
            }
        }

        if (s[2]){
           
            //Light Up the Appropriate Zone
            if (s[2] == 1){
                z[10].color = redZoneColor;
                z[11].color = redZoneColor;
                z[12].color = redZoneColor;
                z[13].color = redZoneColor;
                z[14].color = redZoneColor;
            } else if (s[2] == 2){
                z[11].color = redZoneColor;
                z[12].color = redZoneColor;
                z[13].color = redZoneColor;
                z[14].color = redZoneColor;
            } else if (s[2] == 3){
                z[12].color = redZoneColor;
                z[13].color = redZoneColor;
                z[14].color = redZoneColor;
            } else if (s[2] == 4) {
                z[13].color = redZoneColor;
                z[14].color = redZoneColor;
            } else if (s[2] == 5) {
                z[14].color = redZoneColor;
            }
        }
        
        setZones(z)
        //@ts-ignore
    }, [mapReady, AppState.sensor.detections])
  
   //@ts-ignore
   if (!AppState.user.data) return null;

    return (
        <Row>
            <Col lg="6" md="6" sm="6" xs="12">
            <div style={{height: 500, width: 500, margin: '0 auto'}}>
                    <Worldview blend={{enable: true}} 
                               cameraState={camState}
                                onCameraStateChange={(newCamState) => {
                        console.log(newCamState)
                                setCamState(newCamState)
                    }}>
                    <Cubes blend={{enable: true}}>{zones}</Cubes>
                    
                    <GLTFScene model={dodgeTruck}>
                        {{
          pose: {
            position: { x: 0, y: 0, z: 0 },
            orientation: { x: 0, y: 0, z: 0, w: 1 },
          },
          scale: { x: 3, y: 3, z: 3 },
          blend: {enable: true}
        }}
                        </GLTFScene>

                        
                    </Worldview>
                </div>
            </Col>
            <Col lg="6" md="6" sm="6" xs="12">
                {<BingMapsReact 
                    pushPins={mapReady ? [
                        {               //@ts-ignore
                            center:  { latitude: AppState.sensor.location && AppState.sensor.location.latitude ? AppState.sensor.location.latitude : 33.80941166666666, 
                                        //@ts-ignore
                                        longitude: AppState.sensor.location && AppState.sensor.location.longitude ? AppState.sensor.location.longitude :  -118.05834166666666 },
                            options: {
                                title: "SENSOR LOCATION"
                            }
                        }
                    ]: null}
                    bingMapsKey={process.env.REACT_APP_BING_KEY}
                    height="500px"
                    mapOptions={{
                        navigationBarMode: "square"
                    }}
                    onMapReady={() => {
                        setMapReady(true)
                    }}
                    width="500px"
                    viewOptions={{
                        //@ts-ignore
                        center:  { latitude: AppState.sensor.location && AppState.sensor.location.latitude ? AppState.sensor.location.latitude : 33.80941166666666, 
                            //@ts-ignore
                            longitude: AppState.sensor.location && AppState.sensor.location.longitude ? AppState.sensor.location.longitude :  -118.05834166666666 },
                        mapTypeId: "road",
                        zoom: 18,
                    }}
                />}
            </Col>
            
        </Row>
    )
}