import {combineReducers} from 'redux';
import * as ACTIONS from '../constants/actions';

export default combineReducers({
    results: (state = [], action) => {
        switch(action.type) {
            case ACTIONS.SET_HISTORY: {
                return action.payload;
            }
            default: return state;
        }
    }
})