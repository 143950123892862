import ReactDOM from 'react-dom/client';
import './index.css';
import Navbar from './components/Navbar';
import Login from './components/Login/Login';
import LiveMonitoring from './components/LiveMonitoring/LiveMonitoring';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Provider } from 'react-redux';
import {createStore} from 'redux';
import reducers from './reducers';
import 'bootstrap/dist/css/bootstrap.min.css';
import DetectionHistory from './components/DetectionHistory/DetectionHistory';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const store = createStore(reducers);
root.render(
    <Provider store={store}>
        <div className="spartan-main-container">
        
          <Router>
          <Navbar/>
            <Routes>
              <Route path="/" element={<Login/>} />
              <Route path="/live-monitoring" element={<LiveMonitoring/>}/>
              <Route path="/detection-history" element={<DetectionHistory/>}/>
            </Routes>
          </Router>
        </div>
    </Provider>
    
);


